import * as React from "react";
import { Link } from "gatsby";
import Layout from "../../components/Layout";
import Seo from "../../components/Seo";
import { FocusAreaIcon } from "../../components/svg";
import DownloadBox from "../../components/DownloadBox";
import DigitalHealthEcosystem from "../../images/digital-health-ecosystem.jpg";
import HealthId from "../../images/health-id.png";

const HealthcarePage = () => (
  <Layout>
    <Seo title="Digital Health" />

    {/* Sub Header image */}
    <div className="_healthcare_hero mt-32">
      <div className="flex">
        <FocusAreaIcon icon="healthcare" className="mx-auto w-auto h-40 sm:h-80 -mt-20 text-yellow-800 fill-current" />
      </div>

      {/* Sub Header intro text */}
      <div className="container pt-8 pb-16 sm:pt-32 sm:pb-56 text-white">
        <h1 className="text-4xl sm:text-7xl leading-tight sm:leading-tight font-bold text-center">
          <span className="whitespace-nowrap">
            <span className="text-yellow-800 text-5xl sm:text-8xl pr-1 sm:pr-2">[</span>Digital
          </span>{" "}
          <span className="whitespace-nowrap">
            Health<span className="text-yellow-800 text-5xl sm:text-8xl pl-1 sm:pl-2">]</span>
          </span>
        </h1>
        <p className="text-center text-xl leading-snug sm:text-3xl sm:leading-normal pt-8 sm:pt-16">Levercode’s Approach to Healthcare</p>
      </div>
    </div>

    {/* Content */}
    <div className="container max-w-screen-xl sm:text-2xl pt-8 pb-16 sm:pt-32 sm:pb-48">
      <p>
        Information about our health is one of the most important things to us. The same goes for getting proper hospital treatment or healthcare advice. However, in the case of serious accidents or medical emergencies, it goes far beyond just peace of
        mind - accurate information regarding person’s blood type, chronic health issues, and medication history literally become a matter of life and death. COVID-19 and other communicable diseases have only compounded the{" "}
        <strong>importance of accessing fast and reliable information</strong> in this area. 
      </p>

      <p className="_p">
        Although getting actual health information swiftly is important, it’s also critical that such <strong>information is kept private and shared only with parties that really need it or are authorized to know</strong>. Data systems that contain and
        process health data have to be reliable and provide a high level of protection for sensitive information. The system should also be able to record all data processing such as entry, alteration and viewing events.
      </p>

      <p className="_p _bold">One way to secure the long term reliability and sustainability of the system is to utilize Distributed Ledger Technologies and Blockchain.</p>

      <p className="_p">
        Modern e-Health systems should bring together all relevant stakeholders such as patients, doctors, hospitals, public and private insurers, governments, and other service providers like pharmacies, payment processors etc. Since every stakeholder has
        specific role in this ecosystem, their rights to get and obligations to submit information, are also different.
      </p>

      <p className="_p">
        <strong>Digital Identity - the critical component of the system</strong> - is the basis to provide access to the system and allow/prohibit all activities within it. In terms of providing access to sensitive personal and health data, following the
        „Patient is King“ principle is a must. The patient should decide if his/her health data is shared only with family doctor, or also made available to other stakeholders. 
      </p>

      <p className="_p">
        <img src={HealthId} alt="Digital Health Ecosystem" />
      </p>

      <p className="_p">
        Levercode brings together the experts that have long time experience in e-Health domain and all foundational components (such as Digital Identity, Data Exchange Layer, and Data Registries){" "}
        <strong>that are required to build up modern e-Health ecosystems</strong>. Technologies and standards evolve and our experts have kept up the pace.
      </p>

      <p className="_p">
        <img src={DigitalHealthEcosystem} alt="Digital Health Ecosystem" />
      </p>

      <p className="_p">
        Speaking of registries, e-Health ecosystem is rich in them. Natural part of the fully functional system are registries of the countries population, certified doctors, certified and imported drugs and the Data Exchange Layer (one such solution is
        X-Road), is the glue that binds the registries together and makes it possible to pull together information that is needed for provision of specific health related service.
      </p>

      <p className="_p">
        Levercode designs and models the Foundation and e-Health systems from scratch. However, if a country has the systems already in place but they’re not operating as expected,{" "}
        <strong>our Research Lab is capable of auditing and evaluating existing systems' weaknesses, data and process risk</strong>. We decompose the systems and put our primary focus on the “Red Lines“ – the processes with the highest risk. Naturally, we
        can provide recommendations for remedy or step in to redesign the systems based on industry standards, clients’ needs, and preferences.
      </p>

      <p className="_p">
        Please{" "}
        <Link to="/contact" className="_link font-bold">
          contact us
        </Link>{" "}
        to learn more about Levercode’s approach to building Digital Society and the ways we can build value for your country or organization. 
      </p>

      <DownloadBox text="Download PDF whitepaper – Digital Health Ecosystem" url="https://levercode.com/downloads/digital-health-ecosystem-by-levercode.pdf" />
    </div>
  </Layout>
);

export default HealthcarePage;
